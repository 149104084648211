import * as React from "react"
import { Link, graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Container from "../components/container"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle =
    data.site.siteMetadata?.title || `Find the Best Psychic Service for You`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo
          title="Find the Best Psychic Service for You"
          description="Want to get the best psychic reading ever? Take the quiz to find out which psychic service is best for you. And then read our top 3 psychic hotline reviews"
        />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout
      location={location}
      title={siteTitle}
      className="services-container"
    >
      <Seo
        title="Find the Perfect Psychic Service and Connect to Real Psychics Online"
        description="Want to get the best psychic reading ever? Take the quiz to find out which psychic service is best for you. And then read our top 3 psychic hotline reviews."
      />
      <Container>
        <img
          className="mx-auto"
          src="/psychic-reading-services-hero.svg"
          alt="Best Online Psychic Websites Reviews"
          loading="eager"
          width="520"
          height="284"
        />
        <h1 className="text-sans text-2xl md:text-4xl mb-4">
          Find the Best Psychic Service for You
        </h1>
        <p>
          You’ve decided to connect with a real Psychic, but now you need to
          find the right service for you. That’s where we come in. At Psychic
          Reading Services, you can compare top psychic services by reading
          channel, type of reading, price, and specialty. But don’t worry; if
          you’re not sure what you’re looking for, our quick quiz will recommend
          the best service for you.
        </p>
        <div id="interact-620589a3c870e60018f7828d" />
        <script
          dangerouslySetInnerHTML={{
            __html: `if (window !== "undefined") { window.addEventListener("load", () => setTimeout(loadQuiz, 2000), false); function loadQuiz() { var app_620589a3c870e60018f7828d;(function(d, t){var s=d.createElement(t),options={"appId":"620589a3c870e60018f7828d","width":"800","height":"800","async":true,"host":"quiz.tryinteract.com", "footer":"show"};s.src='https://i.tryinteract.com/embed/app.js';s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs)if(rs!='complete')if(rs!='loaded')return;try{app_620589a3c870e60018f7828d=new InteractApp();app_620589a3c870e60018f7828d.initialize(options);app_620589a3c870e60018f7828d.display();}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr);})(document,'script');}}`,
          }}
        />
      </Container>
      <div className="services-container">
        <div className="section-header global-nav">
          <div className="global-header">
            <h2 className="my-0 text-white text-2xl">
              Our Top 3 Psychic Hotlines
            </h2>
          </div>
        </div>
        <Container>
          <ol style={{ listStyle: `none` }}>
            {posts.map((post, index) => {
              const title = post.frontmatter.url || post.frontmatter.title
              return (
                <li key={post.fields.slug} className="mb-16">
                  <header className="text-black font-bold text-lg mb-2">
                    <p className="mb-0 font-normal">
                      Over {post.frontmatter.yearsOfService} Years in Business
                    </p>
                    <h2 className="text-lg md:text-2xl text-sans uppercase font-bold mt-1">
                      <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{`${
                          index + 1
                        }. ${title}`}</span>
                      </Link>
                    </h2>
                  </header>
                  <article
                    className="post-list-item service max-w-full w-full md:flex"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <div
                      className="service-cover p-8 bg-white h-40 md:h-auto md:w-1/2 flex justify-center bg-contain bg-no-repeat text-center overflow-hidden"
                      title={post.fields.slug}
                    >
                      <a
                        href={`https://www.${post.frontmatter.url}`}
                        target="_blank"
                        rel="noreferrer"
                        className="flex justify-center items-center"
                      >
                        <img
                          className="max-w-full h-auto"
                          src={post.frontmatter.cover}
                          alt={post.frontmatter.title}
                          loading="lazy"
                          width="480"
                          height="480"
                        />
                      </a>
                    </div>
                    <div className="service-content bg-white p-4 flex flex-col justify-between leading-normal w-full md:p-8">
                      <div className="mb-8">
                        <section>
                          <p>
                            <a
                              href={post.frontmatter.enrollUrl}
                              target="_blank"
                              rel="noreferrer"
                              className="text-lg font-bold underline-header"
                            >
                              Special New Customer Offer
                            </a>
                          </p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                `${post.frontmatter.description}` ||
                                post.excerpt,
                            }}
                            itemProp="description"
                          />
                          <ul>
                            {post.frontmatter.featuresFront.map(
                              (feature, index) => {
                                return (
                                  <li key={index} className="mb-8">
                                    <span>
                                      <img
                                        src="/check-mark.svg"
                                        alt="Amazing features!"
                                        loading="lazy"
                                        width="24"
                                        height="24"
                                      />
                                    </span>
                                    {feature}
                                  </li>
                                )
                              }
                            )}
                          </ul>
                          <div className="flex justify-center">
                            <a
                              className="btn btn-min-width"
                              href={post.fields.slug}
                            >
                              Read Review
                            </a>
                          </div>
                        </section>
                      </div>
                    </div>
                  </article>
                </li>
              )
            })}
          </ol>
        </Container>
      </div>
      <Container>
        <Bio />
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          cover
          featuresFront
          yearsOfService
          url
          rating
          reviewerRating
          ratePerMinute
          channels
          specialties
          satisfaction
          experts {
            name
            channels
            tagline
            url
            picture
          }
          appStoreUrl
          playStoreUrl
          enrollUrl
        }
      }
    }
  }
`
