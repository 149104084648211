/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author

  return (
    <div className="bio border border-gray-200 p-8 rounded">
      {/* <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/profile-pic.jpg"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
      /> */}
      <img
        className="bio-avatar h-12 max-h-full"
        src="/profile-pic.jpg"
        alt="Nice to meet you!"
        loading="lazy"
        width="50"
        height="48"
      />
      {author?.name && (
        <div>
          <p className="mb-2 underline-header">Reviewer Bio</p>
          <p>
            <strong>{author.name}</strong>
          </p>{" "}
          <p>{author?.summary || null}</p>
          {` `}
        </div>
      )}
    </div>
  )
}

export default Bio
